


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import userDetail from '@/components/UserDetail/index.vue';
import {
  UserListEditSvc,
  UserPermissionSvc,
  accountDetailsSvc,
} from '@/services/systemApi';

@Component({
  components: {
    userDetail,
  },
})
export default class OperateUser extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: {},
  })
  data!: '';
  $dayjs: any;
  $domainConfig: any;
  userName = '';
  phone = '';
  en_tel = '';
  message = '';
  value = '';
  functionPermission: any = {};
  loading = true;
  data1: any = [];
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  user1 = 'normal';
  numOfProject = 0;
  showDetail = false;
  async addService(): Promise<void> {
    this.showDetail = true;
    const res = await UserPermissionSvc({ en_tel: (this.data as any).en_tel, tel: '' });
    if (res && (res as any).data && (res as any).data.user_mid_ary) {
      this.numOfProject = (res as any).data.user_mid_ary.length;
    }
    if (res && (res as any).data && (res as any).data.page_url_obj) {
      this.functionPermission = (res as any).data.page_url_obj;
    }
    const res1 = await accountDetailsSvc({});
    if (!res1.status && (res1 as any).data && (res1 as any).data.length) {
      this.loading = false;
      (res1 as any).data.forEach((i: any) => {
        this.data1.push({
          id: i.mall_id,
          projectName: i.name,
          province: i.province,
          city: i.city,
          district: i.district,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _checked:
            (res as any)
            && (res as any).data.user_mid_ary
            && !!(res as any).data.user_mid_ary.find((j: any) => j === i.mall_id),
        });
      });
    }
  }
  returnHome(): void {
    this.modal1 = true;
  }
  ok(): void {
    this.$emit('returnHomePage', false);
  }
  returnHomePage(): void {
    this.$emit('returnHomePage', false);
  }
  cancel(): void {
    this.modal1 = false;
  }
  async mounted(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const res = await UserListEditSvc({ en_tel: (this.data as any).en_tel });
    // console.log(this.data, location.origin);
    if ((res as any) && (res.data as any)) {
      this.userName = res.data.name;
      this.phone = res.data.tel;
      this.en_tel = res.data.en_tel;
      this.message = res.data.co_name;
      this.value = res.data.name;
    }
    this.value = (this.data as any).name;
    this.user1 = (this.data as any).usertype === '普通用户' ? 'normal' : 'boss';
  }
}
