

























































































import { Vue, Component } from 'vue-property-decorator';
import {
  accountDetailsSvc,
  getUserDetailsSvc,
  changeUserStatusSvc,
} from '@/services/systemApi';
import appendUser from './appendUser/index.vue';
import operateUser from './operateUser/index.vue';
import { userModule } from '@/store/modules/user';
@Component({
  components: {
    appendUser,
    operateUser,
  },
})
export default class Details extends Vue {
  $dayjs: any;
  loading = true;
  loading2 = true;
  modal2 = false;
  modal1 = false;
  userInformation = '';
  columns1 = [
    {
      title: '项目名称',
      key: 'name',
      align: 'center',
      tooltip: true,
    },
    {
      title: '省份',
      key: 'province',
      align: 'center',
    },
    {
      title: '城市',
      key: 'city',
      align: 'center',
    },
    {
      title: '区县',
      key: 'district',
      align: 'center',
    },
    {
      title: '服务周期',
      key: 'period',
      align: 'center',
      elliphsis: true,
      width: 210,
    },
    {
      title: '服务状态',
      key: 'status',
      align: 'center',
      slot: 'status',
    },
  ];
  numOfProject = 0;
  serveName = userModule.serviceName;
  appendUserStatus = false;
  editUserStatus = false;
  data2: any = [];
  operateInfo: any = [];
  columns2 = [
    {
      title: '用户姓名',
      key: 'name',
      align: 'center',
    },
    {
      title: '手机号',
      key: 'phone',
      align: 'center',
    },
    {
      title: '用户类型',
      key: 'usertype',
      align: 'center',
    },
    {
      title: '关联项目数',
      key: 'project',
      align: 'center',
    },
    {
      title: '操作',
      key: 'operate',
      slot: 'operate',
      align: 'center',
    },
  ];
  data1: any = [];

  get userShow(): boolean {
    const privilege =  Number(this.$store.state.user.privilege);
    return privilege === 1 || privilege === 2;
  }

  async mounted(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.projectInfo();
    this.userInfo();
  }
  modalShow(): void {
    this.modal2 = false;
  }
  async projectInfo(): Promise<void> {
    const res = await accountDetailsSvc();
    this.data2 = [];
    if ((res as any) && (res.data as any)) {
      this.loading = false;
      this.numOfProject = res.data.length;
      res.data.forEach((i: any) => {
        const serviceCycle = i.serviceCycles
          .map((item: any) => `${this.$dayjs(item.service_cycle_start).format('YYYY/MM/DD')}-${this.$dayjs(item.service_cycle_end).format('YYYY/MM/DD')}`)
          .join('\n');
        this.data2.push({
          name: `${i.city} ${i.district} ${i.name}`,
          province: i.province,
          city: i.city,
          district: i.district,
          period: serviceCycle,
          status: i.status,
        });
      });
    }
  }
  async userInfo(): Promise<void> {
    const res = await getUserDetailsSvc();
    this.data1 = [];
    if ((res as any) && (res.data as any)) {
      this.loading2 = false;
      res.data.forEach((i: any) => {
        this.data1.push({
          name: i.user,
          phone: i.tel,
          en_tel: i.en_tel,
          usertype: i.privilege === 3 ? '普通用户' : '企业管理员',
          project: i.mall_count,
          status: i.status,
        });
      });
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  changeUserStatus(row: any): void {
    this.userInformation = row;
    this.modal1 = true;
  }
  async ok(): Promise<void> {
    const res = await changeUserStatusSvc({
      status: (this.userInformation as any).status === 1 ? 0 : 1,
      en_tel: (this.userInformation as any).en_tel,
    });
    if (!res.status) {
      this.modal2 = true;
      this.userInfo();
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editUser(row: any): void {
    this.operateInfo = row;
    this.editUserStatus = true;
  }
  appendUser(): void {
    this.appendUserStatus = true;
  }
  returnHomePage(val: boolean): void {
    this.appendUserStatus = val;
    this.editUserStatus = val;
    this.userInfo();
  }
}
